<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-14 16:07:16
 * @LastEditTime: 2021-05-13 09:36:16
 * @FilePath: /shop_frontend/src/views/goodsList/list.vue
-->
<template>
  <div>
    <div class="formBox">
      <a-form :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <!-- <a-col :span="8">
            <a-form-item label="商品名称">
              <a-input-search placeholder="搜索商品" style="width: 220px" v-model="searchForm.search" allowClear />
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="8">
            <a-form-item label="商家分类">
              <a-select style="width: 220px" placeholder="请选择商家分类">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="8">
            <a-form-item label="商家标签">
              <a-select style="width: 220px" placeholder="请选择商家标签">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="8">
            <a-form-item label="上架时间">
              <a-range-picker :placeholder="['开始时间', '结束时间']" allowClear>
              </a-range-picker>
            </a-form-item>
          </a-col> -->
          <a-col :span="8">
            <a-form-item label="商品分类">
              <a-select style="width: 220px" placeholder="请选择商品分类" v-model="searchForm.category" allowClear>
                <a-select-option :value="item.id" :key="item.id" v-for="item in cateList">
                  {{ item.name }}({{ item.goods_count }})</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="8">
            <a-form-item label="活动类型">
              <a-select style="width: 220px" placeholder="请选择活动类型">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="24">
            <a-form-item label="商品价格">
              <div class="search__price">
                <a-input placeholder="输入价格" style="width: 220px" allowClear />
                <span>至</span>
                <a-input placeholder="输入价格" style="width: 220px" allowClear />
              </div>
            </a-form-item>
          </a-col> -->
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <a-button type="primary" html-type="submit" style="margin-right: 10px" @click="onSearch">
                查询
              </a-button>
              <!-- <a-button type="primary" style="margin-right: 10px">
                重置
              </a-button> -->
              <!-- <a-button type="primary" style="margin-right: 10px">
                导出商品
              </a-button> -->
              <a-button type="primary" @click="$router.push({ name: 'goodsCreate' })">
                添加商品
              </a-button>
              <a-divider type="vertical" />
              <a-button type="primary" @click="downloadTemple">
                下载模板
              </a-button>
              <a-divider type="vertical" />
              <a-button type="primary" @click="exportGoods">
                导出商品
              </a-button>
              <a-divider type="vertical" />
              <a-upload
                name="file"
                :headers="{
                  'X-CSRFToken': Cookies.get('csrftoken'),
                }"
                :multiple="false"
                action="/api/goods_files/upload/"
                :beforeUpload="beforeUpload"
              >
                <a-button> <a-icon type="upload" />上传商品</a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="page__table">
      <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" @change="changeTable" bordered>
        <div slot="goods_name" slot-scope="text, record" class="goods_name_box">
          <img
            :src="record.picture_items && record.picture_items[0] && record.picture_items[0].image"
            alt=""
            class="list__img"
          />
          <span class="goods_name">{{ record.name }}</span>
        </div>
        <div slot="price" slot-scope="text, record">
          <span>￥{{ record.min_price || 0 }}</span>
          -
          <span>￥{{ record.max_price || 0 }}</span>
        </div>
        <template slot="kc" slot-scope="text, record">
          <!-- <editable-cell
            :text="text"
            @change="onCellChange(record, $event)"
          /> -->
          {{ text }}
        </template>
        <div slot="action" slot-scope="text, record" class="action">
          <a-icon type="edit" @click="openDetail(record)" />
          <a-icon type="copy" @click="openCopy(record)" />
          <a-icon type="share-alt" @click="onShare(record)" />
          <a-icon type="delete" @click="onDelete(record)" />
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { exportFile } from "@/utils/export";
const columns = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    key: "goods_name",
    scopedSlots: { customRender: "goods_name" },
    width: 300,
    sorter: (a, b) => a.name.length - b.name.length,
    align: "center",
  },
  // {
  //   title: "规格",
  //   dataIndex: "spec_fields",
  // },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
    scopedSlots: { customRender: "price" },
    align: "center",
  },
  // {
  //   title: "成本价格",
  //   dataIndex: "cost_price",
  //   key: "cost_price",
  // },
  // {
  //   title: "市场价格",
  //   dataIndex: "market_price",
  //   key: "market_price",
  // },
  {
    title: "商品货号",
    dataIndex: "number",
    key: "number",
    align: "center",
  },
  {
    title: "商品分类",
    dataIndex: "category_name",
    key: "category_name",
    align: "center",
  },

  {
    title: "库存",
    dataIndex: "inventory_quantity",
    scopedSlots: { customRender: "kc" },
    sorter: (a, b) => a.inventory_quantity - b.inventory_quantity,
    align: "center",
  },
  {
    title: "销量",
    dataIndex: "sales_quantity",
    sorter: (a, b) => a.sales_quantity - b.sales_quantity,
    align: "center",
  },
  // {
  //   title: "重量",
  //   dataIndex: "weight",
  //   key: "weight",
  //   sorter: (a, b) => a.weight - b.weight,
  // },
  // {
  //   title: "库存下限",
  //   dataIndex: "inventory_lower",
  //   key: "inventory_lower",
  // },
  // {
  //   title: "库存预警",
  //   dataIndex: "open_inventory_warnning",
  //   key: "open_inventory_warnning",
  // },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: (a, b) => a.create_time - b.create_time,
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
const Cookies = window.Cookies;

export default {
  components: {
    // EditableCell: () => import("../goodsSoldOut/EditableCell"),
  },
  props: {
    is_active: {
      type: Boolean,
    },
  },
  data() {
    return {
      Cookies: Cookies,
      searchForm: {
        search: "",
        category: "",
      },
      dataSource: [],
      columns,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      form: this.$form.createForm(this, { name: "advanced_search" }),
      page: 1,
    };
  },
  computed: {
    params() {
      return {
        is_active: this.is_active,
        category: this.searchForm.category,
        search: this.searchForm.search,
        page: this.page,
      };
    },
  },
  created() {
    this.initTable();
    this.initCategories();
  },
  methods: {
    uploadGoods() {
      this.$axios.post("/goods_files/upload/", {});
    },
    exportGoods() {
      var that = this;
      this.$axios
        .get("/goods_files/export/", {
          responseType: "blob",
          params: that.searchForm,
        })
        .then((data) => {
          exportFile(data, "商品", "xls");
          this.$message.success("导出成功");
        });
    },
    downloadTemple() {
      this.$axios
        .get("/goods_files/download_template/", {
          responseType: "blob",
        })
        .then((data) => {
          exportFile(data, "商品模板", "xls");
          this.$message.success("导出成功");
        });
    },
    changeTable(pagination) {
      this.page = pagination.current;
      this.initTable();
    },
    openDetail(record) {
      this.$router.push({
        name: "goodsCreate",
        query: {
          id: record.id,
        },
      });
    },
    openCopy(record) {
      this.$router.push({
        name: "goodsCreate",
        query: {
          id: record.id,
          copy: true,
        },
      });
    },
    initCategories() {
      this.$axios
        .get("/categories/", {
          params: {
            page_size: 999999,
          },
        })
        .then((res) => {
          this.cateList = res.results || [];
        });
    },
    onShare(record) {
      const h = this.$createElement;
      this.$axios.get(`/goods/${record.id}/qr_code/`).then((res) => {
        console.log(res, "00");
        this.$info({
          class: "custom-info",
          title: "扫一扫，分享给好友吧",
          content: h("div", { class: "custom_div" }, [
            h("img", {
              attrs: { src: res.qr_code_url || "" },
              style: { width: "200px", height: "200px", display: "block" },
            }),
          ]),
          onOk() {},
        });
      });
    },
    onSearch() {
      this.initTable();
    },
    onCellChange(record, value) {
      this.$axios.post(`/goods_specs/${record.id}/set_inventory/`, {
        quantity: value,
      });
    },
    initTable() {
      this.$axios.get("/goods/", { params: this.params }).then((res) => {
        console.log(res, "---");
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    onDelete(record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/goods/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 512;
      console.log(isLt500Kb, file.size / 1024);
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(512KB)，请修改后重新上传");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.search__price {
  display: flex;
  align-items: center;
  > span {
    margin: 0 10px;
  }
}
.list__img {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.action {
  font-size: 20px;
  color: #1890ff;
  > i {
    margin-right: 10px;
  }
}
.goods_name {
  display: inline-block;
  // width: 100px;
}
.goods_name_box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
</style>

<style lang="less">
.custom-info {
  .anticon-info-circle {
    display: none;
  }
  .ant-modal-confirm-content {
    margin-left: 0 !important;
  }
  .custom_div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}
</style>
